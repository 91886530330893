<template>
  <div class="container-fluid footer-header">
    <div class="row mt-4 text-white text-center">
      <div class="col-12 col-xl-12 mt-4">
        <h3>PROJEKT PODPIRAJO:</h3>
      </div>
    </div>
    <div class="row mt-4 text-white text-center">
      <div class="col-12 offset-xl-3 col-xl-2">
        <a href="https://www.ribnica.si/"
          ><img
            width="50px"
            height="55px"
            class="mb-1"
            src="https://postaje.vreme-podnebje.si/images/footer/grb_ribnica.png"
            alt="ribnica"
        /></a>
        <p>Občina Ribnica</p>
      </div>
      <div class="col-12 col-xl-2">
        <a href="https://www.loski-potok.si/"
          ><img
            width="50px"
            height="55px"
            class="mb-1"
            src="https://postaje.vreme-podnebje.si/images/footer/grb_loski_potok.png"
            alt="loski_potok"
        /></a>
        <p>Občina Loški Potok</p>
      </div>
       <div class="col-12 col-xl-2">
        <a href="https://sodrazica.si/"
          ><img
            width="50px"
            height="55px"
            class="mb-1"
            src="https://postaje.vreme-podnebje.si/images/footer/grb_sodrazica.png"
            alt="loski_potok"
        /></a>
        <p>Občina Sodražica</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 text-center footer-end p-3">
        <p class="mt-2">© 2021 Vreme-podnebje.si - Vse pravice pridržane.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.fb {
  width: 60px;
  height: 60px;
  color: rgb(30, 30, 184);
}

.tw {
  width: 60px;
  height: 60px;
  color: rgb(56, 179, 228);
}

.ig {
  width: 60px;
  height: 60px;
  color: rgb(236, 101, 101);
}

.yt {
  width: 60px;
  height: 60px;
  color: rgb(184, 31, 31);
}

.footer-header {
  background-color: #202020;
  color: #ededed;
}

.footer-end {
  background-color: black;
  color: white;
}

p {
  font-size: 0.8rem;
}
</style>
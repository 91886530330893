import AxiosUrl from './AxiosUrl';

var config = {
  method: "get",
  url: AxiosUrl.LoskiPotokDetails.Hrib.Daily.Url,
  headers: {
    Authorization: process.env.VUE_APP_API,
  },
};

var davisConfig = {
  method: "get",
  url: '',
  headers: { 
    'X-Api-Key': process.env.VUE_APP_DAVIS_API
  }
}

function GetConfig(url) {
  config.url = url;
  return config;
}

function GetDavisConfig(url) {
  davisConfig.url = url;
  return davisConfig;
}

export {
  GetConfig,
  GetDavisConfig
}

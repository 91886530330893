const stationIDs = [
    {
        temp: process.env.VUE_APP_HRIB_TEMPERATURE_ID,
        hum: process.env.VUE_APP_HRIB_HUMIDITY_ID,
        pressure: process.env.VUE_APP_HRIB_PRESSURE_ID,
        rain: process.env.VUE_APP_HRIB_RAIN_ID,
        rainDay: process.env.VUE_APP_HRIB_RAIN_DAY_ID,
        rainMonth: process.env.VUE_APP_HRIB_RAIN_MONTH_ID,
        rainYear: process.env.VUE_APP_HRIB_RAIN_YEAR_ID,
        windSpeed: process.env.VUE_APP_HRIB_WIND_SPEED_ID,
        windGust: process.env.VUE_APP_HRIB_WIND_GUST_ID,
        dewpoint: process.env.VUE_APP_HRIB_DEWPOINT_ID,
    },
    {
        temp: process.env.VUE_APP_RETJE_TEMPERATURE_ID,
        hum: process.env.VUE_APP_RETJE_HUMIDITY_ID,
        pressure: null,
        rain: null,
        rainDay: null,
        windSpeed: null,
        windGust: null,
        dewpoint: process.env.VUE_APP_RETJE_DEWPOINT_ID,
    },
    {
        temp: process.env.VUE_APP_PRIGORICA_TEMPERATURE_ID,
        hum: process.env.VUE_APP_PRIGORICA_HUMIDITY_ID,
        pressure: process.env.VUE_APP_PRIGORICA_PRESSURE_ID,
        rain: process.env.VUE_APP_PRIGORICA_RAIN_ID,
        rainDay: process.env.VUE_APP_PRIGORICA_RAIN_DAY_ID,
        rainMonth: process.env.VUE_APP_PRIGORICA_RAIN_MONTH_ID,
        rainYear: process.env.VUE_APP_PRIGORICA_RAIN_YEAR_ID,
        windSpeed: process.env.VUE_APP_PRIGORICA_WIND_SPEED_ID,
        windGust: process.env.VUE_APP_PRIGORICA_WIND_GUST_ID,
        dewpoint: process.env.VUE_APP_PRIGORICA_DEWPOINT_ID,
        uv: process.env.VUE_APP_PRIGORICA_UV_ID
    },
    {
        temp: process.env.VUE_APP_RIBNICA_TEMPERATURE_ID,
        hum: process.env.VUE_APP_RIBNICA_HUMIDITY_ID,
        pressure: null,
        rain: process.env.VUE_APP_RIBNICA_RAIN_ID,
        rainDay: process.env.VUE_APP_RIBNICA_RAIN_DAY_ID,
        rainMonth: process.env.VUE_APP_RIBNICA_RAIN_MONTH_ID,
        rainYear: process.env.VUE_APP_RIBNICA_RAIN_YEAR_ID,
        windSpeed: process.env.VUE_APP_RIBNICA_WIND_SPEED_ID,
        windGust: process.env.VUE_APP_RIBNICA_WIND_GUST_ID,
        dewpoint: process.env.VUE_APP_RIBNICA_DEWPOINT_ID,
    },
    {
        temp: process.env.VUE_APP_SVETA_ANA_TEMPERATURE_ID,
        hum: process.env.VUE_APP_SVETA_ANA_HUMIDITY_ID,
        pressure: process.env.VUE_APP_SVETA_ANA_PRESSURE_ID,
        dewpoint: process.env.VUE_APP_SVETA_ANA_DEWPOINT_ID,
    }
]

export {
    stationIDs
}
export let stationList = {
    firstRow: [
        {
            id: 1,
            title: "RIBNICA",
            attitude: 492,
            stationType: "nižinska",
            refreshDate: null,
            temp: null,
            hum: null,
            color: "border-danger",
            details: "/details/ribnica/Cy3kas34kV/daily",
            url: "https://postaje.vreme-podnebje.si/images/details/ribnica.jpg"
        },
        {
            id: 2,
            title: "PRIGORICA",
            attitude: 486,
            stationType: "nižinska",
            refreshDate: null,
            temp: null,
            hum: null,
            color: "border-warning",
            details: "/details/prigorica/KMn2x1KsWgQ/daily",
            url: "https://postaje.vreme-podnebje.si/images/details/prigorica.jpg"
        },
        {
            id: 3,
            title: "GAŠPINOVO",
            attitude: 721,
            stationType: "višinska",
            refreshDate: null,
            temp: null,
            hum: null,
            color: "border-info",
            details: "/details/slemena/HkumAw2XoQs/daily",
            url: "https://postaje.vreme-podnebje.si/images/details/gaspinovo.jpg"
        }
    ],
    secondRow: [
        {
            id: 4,
            title: "SVETA ANA",
            attitude: 910,
            stationType: "višinska",
            refreshDate: null,
            temp: null,
            hum: null,
            color: "border-primary",
            details: "/details/sv-ana/Rds23w5XoyD/daily",
            url: "https://postaje.vreme-podnebje.si/images/details/svetaAna.png"
        },
        {
            id: 5,
            title: "HRIB",
            attitude: 773,
            stationType: "višinska",
            refreshDate: null,
            temp: null,
            hum: null,
            color: "border-success",
            details: "/details/loski-potok/LYk4p9r8RA/daily",
            url: "https://postaje.vreme-podnebje.si/images/details/hrib.jpg"
        },
        {
            id: 6,
            title: "RETJE",
            attitude: 705,
            stationType: "mraziščarska",
            refreshDate: null,
            temp: null,
            hum: null,
            color: "border-secondary",
            details: "/details/loski-potok/NxEkuvG2VT/daily",
            url: "https://postaje.vreme-podnebje.si/images/details/retje.jpg"
        }
    ]
}

export default stationList;
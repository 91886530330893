export var Settings = {
    temperature:
    {
        name: 'Temperatura',
        value: null,
        minName: 'Najnižja',
        minValue: null,
        minTime: '06:37',
        maxName: 'Najvišja',
        maxValue: null,
        maxTime: '16:10',
        avgName: 'Povprečna',
        avgValue: null,
        unit: '°C',
    },
    humidity: {
        name: 'Vlaga',
        value: null,
        minName: 'Najnižja',
        minValue: null,
        minTime: '16:26',
        maxName: 'Najvišja',
        maxValue: null,
        maxTime: '09:04',
        avgName: 'Povprečna',
        avgValue: null,
        unit: '%',
    },
    pressure: {
        name: 'Zračni tlak',
        value: null,
        minName: 'Najnižji',
        minValue: null,
        minTime: '16:55',
        maxName: 'Najvišji',
        maxValue: null,
        maxTime: '06:37',
        avgName: 'Povprečen',
        avgValue: null,
        unit: 'hPa'
    },
    rain: {
        name: 'Dnevne padavine',
        value: null,
        minName: 'Zadnjo uro',
        minValue: null,
        minTime: null,
        maxName: null,
        maxValue: null,
        maxTime: null,
        avgName: null,
        avgValue: null,
        unit: 'mm',
        unitH: null
    },
    windSpeed: {
        name: 'Hitrost vetra',
        value: null,
        minName: 'Najmočnejši sunek',
        minValue: null,
        minTime: null,
        maxName: 'Povprečna hitrost',
        maxValue: null,
        maxTime: null,
        avgName: null,
        avgValue: null,
        unit: 'km/h'
    },
    dewpoint: {
        name: 'Rosišče',
        value: null,
        minName: 'Najnižje',
        minValue: null,
        minTime: '10:23',
        maxName: 'Najvišje',
        maxValue: null,
        maxTime: '17:23',
        avgName: 'Povprečno',
        avgValue: null,
        unit: '°C'
    },
    uv: {
        name: 'Sončno obsevanje',
        value: null,
        minName: 'Najnižje',
        minValue: null,
        minTime: '05:23',
        maxName: 'Najvišje',
        maxValue: null,
        maxTime: '15:23',
        avgName: 'Povprečno',
        avgValue: null,
        unit: 'W/m2'
    }
}

export default Settings;
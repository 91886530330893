<template>
  <div class="container-fluid p-3 desktop-text" id="header">
    <div class="row">
      <div class="col-12 col-xl-5 offset-xl-2 col-lg-10 offset-lg-1">
        <a href="/">
          <h5 class="d-inline">Vreme 24/7 </h5><span> – vse o vremenu od Kolpe do Barja in širše</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HeadNav',
}
</script>

<style scoped>
#header {
  background-color: #202020;
  color: white;
}

@media (max-width: 751px) {
  .desktop-text {
    display: none;
  }
}

a {
  color: white;
  text-decoration: none; /* no underline */
}
</style>

<template>
  <div>
    <HeadNav />
    <MainNav />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import MainNav from "./components/Nav/MainNav";
import HeadNav from "./components/Nav/HeadNav";
import Footer from "./components/Footer/Footer.vue";

export default {
  name: "App",
  components: {
    MainNav,
    HeadNav,
    Footer,
  },
};
</script>

<style>
body {
  background: #e9e9e9
    url(https://jumpstartthemespreview.com/base-admin-3.0/img/tiny_grid.png)
    repeat 0 0;
}
</style>

export const InitialChartSettings = {
    Temperature: {
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
        },
        yAxis: {
            title: {
                text: 'Temperatura °C'
            },
        },
        series: [
            {
                name: 'Hrib',
                color: 'red',
                data: []
            },
            {
                name: 'Retje',
                color: 'blue',
                data: []
            }
        ]
    },
    Temp: {
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
        },
        yAxis: {
            title: {
                text: 'Temperatura °C'
            },
            tickPositioner: null
        },
        series: [
            {
                name: 'Hrib',
                color: 'red',
                data: []
            },
        ],
    },
    Humidity: {
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
        },
        yAxis: {
            title: {
                text: 'Vlaga %'
            },
        },
        series : [
            {
                name: 'Vlaga',
                color: 'green',
                data: []
            }
        ]
    },
    Pressure: {
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
        },
        yAxis: {
            title: {
                text: 'Zračni tlak hPa'
            },
        },
        series : [
            {
                name: 'Zračni tlak',
                color: 'purple',
                data: []
            }
        ]
    },
    Rain: {
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
        },
        yAxis: {
            title: {
                text: 'Padavine mm'
            },
        },
        series: [
            {
                type: 'column',
                name: 'Padavine',
                color: 'blue',
                data: []
            }
        ]
    },
    Wind: {
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
        },
        yAxis: {
            title: {
                text: 'Veter m/s'
            },
        },
        series: [
            {
                name: 'Sunki vetra',
                color: 'black',
                data: []
            },
            {
                name: 'Hitrost vetra',
                color: 'gray',
                data: []
            }
        ],
    },
    Dewpoint: {
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
        },
        yAxis: {
            title: {
                text: 'Rosišče °C'
            },
        },
        series: [
            {
                name: 'Rosišče',
                color: 'orange',
                data: []
            }
        ]
    },
    UV: {
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
        },
        yAxis: {
            title: {
                text: 'Sončno obsevanje W/m2'
            },
        },
        series: [
            {
                name: 'Sončno obsevanje',
                color: 'tomato',
                data: []
            }
        ]
    }
}
import { ChartSettings } from "./ChartSettings";
import TableSettings from "./TableSettings";

export var StationSettings = {
    ChartSettings: ChartSettings,
    TableSettings: TableSettings,
    RecordSettings: [],
    DetailsSettings: null,
    LastRefresh: null
}

export default StationSettings;
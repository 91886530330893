function DegToText(degree) {
    let degText = "";
    if (degree >= 0 && degree <= 20) {
        degText = "S";
    }
    else if (degree > 20 && degree <= 70) {
        degText = "SV";
    }
    else if (degree > 70 && degree <= 100) {
        degText = "V";
    }
    else if (degree > 100 && degree <= 160) {
        degText = "JV";
    }
    else if (degree > 160 && degree <= 190) {
        degText = "J";
    }
    else if (degree > 190 && degree <= 250) {
        degText = "JZ";
    }
    else if (degree > 250 && degree <= 280) {
        degText = "Z";
    }
    else if (degree > 280 && degree <= 340) {
        degText = "SZ";
    }
    else if (degree > 340 && degree <= 360) {
        degText = "S";
    }
    else {
        degText = "";
    }
    console.log(degText);
    return degText;
}

export {
    DegToText
}
<template>
  <div class="container-fluid bg-dark" id="container">
    <div class="row">
      <div class="col-12 col-lg-10 offset-lg-2 col-lg-10 offset-lg-1 mobile">
        <nav class="navbar navbar-expand-lg navbar-dark p-0 mobile-padding">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <a href="/" class="navbar-brand mobile-text">Vreme 24/7</a><p class="text-white mobile-text m-2 p-2 special-text">Vse o vremenu od Kolpe do Barja in širše</p>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav text-center">
              <li class="nav-item">
                <a href="/ribnica" class="nav-link">
                  <img class="img-fluid" style="width: 40px; height: 40px;" src="https://postaje.vreme-podnebje.si/images/grb/ribnica.png" alt="image" />
                  <span id="style-title">Ribnica</span>
                </a>
              </li>
              <li class="nav-item"></li>
              <li class="nav-item">
                <a href="/loski-potok" class="nav-link">
                  <img class="img-fluid" style="width: 40px; height: 40px;" src="https://postaje.vreme-podnebje.si/images/grb/loskipotok.png" alt="image1" />
                  <span id="style-title">Loški Potok</span>
                </a>
              </li>
              <li class="nav-item">
                <a href="/sodrazica" class="nav-link">
                  <img class="img-fluid" style="width: 40px; height: 40px;" src="https://postaje.vreme-podnebje.si/images/grb/sodrazica.png" alt="image1" />
                  <span id="style-title">Sodražica</span>
                </a>
              </li>
            </ul>
            <ul class="navbar-nav me-auto navbar-center text-center">
              <li class="nav-item">
                <a href="/stations" class="nav-link express">
                  <fa icon="clipboard-list" id="style-icon"></fa>
                  <span id="style-title">Podrobnejši podatki</span>
                </a>
              </li>
              <li class="nav-item">
                <a href="/statistics" class="nav-link express">
                  <fa icon="feather-alt" id="style-icon"></fa>
                  <span id="style-title">Vremenske statistike</span>
                </a>
              </li>
              <li class="nav-item">
                <a href="/encyclopedia" class="nav-link express">
                  <fa icon="journal-whills" id="style-icon"></fa>
                  <span id="style-title">Vremenkopedija</span>
                </a>
              </li>
              <li class="nav-item">
                <a href="/about" class="nav-link express">
                  <fa icon="chess-rook" id="style-icon"></fa>
                  <span id="style-title">O projektu</span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainNav",
  components: {},
  data() {
    return {
      componentKey: 0,
    };
  },
   methods: {
    forceRerender() {
      this.componentKey += 1;  
    }
  }
};
</script>

<style scoped>
@media (min-width:1344.98px) {
.navbar-nav.navbar-center {
    position: absolute;
    left: 45%;
    transform: translatex(-50%);
}
 }


.express:hover #style-title {
  /*color: #f90;*/
}

.express:hover #style-icon {

}

#style-icon {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: auto;
}

#style-title {
  display: block;
  font-size: 14px;
}

.home {
  top: 2px;
  left: 12px;
}

.camera {
  left: 35px;
}

.express {
  padding: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.navbar-collapse.collapsing,
.navbar-collapse.show {
  text-align: center;
  margin: auto;
}

.navbar-collapse.show #style-icon {
  left: 0px;
  right: 0px;
}

.navbar-collapse.collapsing #style-icon {
  left: 0px;
  right: 0px;
}


@media (min-width: 751px) {
  .mobile-text {
    display: none;
  }
}

@media (max-width: 751px) {

  .row {
    padding: 10px;
  }

  .special-text {
    font-size: 14px;
  }
}
</style>

import moment from 'moment';

class Ribnica {
    constructor() {
        this.original = [];
        this.stats = {};
    }

    RemovedInvalidChartSeries(StationSettings) {
        if (StationSettings.ChartSettings.Temperature.series.length > 2) {
            StationSettings.ChartSettings.Temperature.series.shift();
        }
    }

    PrepareOriginal(originalArray) {
        originalArray.forEach(x => {
            var original = new Original(
                x.prigoricaTemperature,
                x.prigoricaHumidity,
                x.ribnicaTemperature,
                x.ribnicaHumidity,
                x.svAnaTemperature,
                x.svAnaHumidity,
                x.date
            )
            this.original.push(original);
        });
    }

    PrepareStats(statsData) {
        var stats = new Stats(
            statsData.prigoricaCurrentTemperature,
            statsData.prigoricaCurrentHumidity,
            statsData.prigoricaMaxTemperature,
            statsData.prigoricaMinTemperature,
            statsData.lastUpdatedPrigorica,
            statsData.ribnicaCurrentTemperature,
            statsData.ribnicaCurrentHumidity,
            statsData.ribnicaMaxTemperature,
            statsData.ribnicaMinTemperature,
            statsData.lastUpdatedRibnica,
            statsData.svAnaCurrentTemperature,
            statsData.svAnaCurrentHumidity,
            statsData.svAnaMaxTemperature,
            statsData.svAnaMinTemperature,
            statsData.lastUpdatedSvAna
        )
        this.stats = stats;
    }

    AddChartData(StationSettings) {
        StationSettings.ChartSettings.Temperature.series[0].data = this.original.map(x => x.ribnicaTemperature);
        StationSettings.ChartSettings.Temperature.series[1].data = this.original.map(x => x.prigoricaTemperature);

        let svAna = this.original.map(x => x.svAnaTemperature);
        return svAna;
    }

    AddChartProperties(StationSettings) {
        StationSettings.ChartSettings.Temperature.series[0].name = 'Ribnica';
        StationSettings.ChartSettings.Temperature.series[0].color = 'red';
        StationSettings.ChartSettings.Temperature.series[1].name = 'Prigorica';
        StationSettings.ChartSettings.Temperature.series[1].color = '#ff8c00';
    }

    AddTableData(StationSettings) {
        StationSettings.TableSettings.Ribnica[0].temp = this.stats.ribnicaCurrentTemperature;
        StationSettings.TableSettings.Ribnica[0].date = moment(this.stats.lastUpdatedRibnica).format('DD. MM. Y HH:mm');
        StationSettings.TableSettings.Ribnica[0].hum = this.stats.ribnicaCurrentHumidity;
        StationSettings.TableSettings.Ribnica[1].temp = this.stats.prigoricaCurrentTemperature
        StationSettings.TableSettings.Ribnica[1].date = moment(this.stats.lastUpdatedPrigorica).format('DD. MM. Y HH:mm');
        StationSettings.TableSettings.Ribnica[1].hum = this.stats.prigoricaCurrentHumidity;
    }

    AddSvAnaTableData(StationSettings) {
        StationSettings.TableSettings.Ribnica[3].temp = this.stats.svAnaCurrentTemperature;
        StationSettings.TableSettings.Ribnica[3].date = moment(this.stats.lastUpdatedSvAna).format('DD. MM. Y HH:mm');
        StationSettings.TableSettings.Ribnica[3].hum = this.stats.svAnaCurrentHumidity;
    }

    AddRecordData(StationSettings, gaspinovo) {
        StationSettings.RecordSettings.push({min: this.stats.ribnicaMinTemperature, max: this.stats.ribnicaMaxTemperature});
        StationSettings.RecordSettings.push({min: this.stats.prigoricaMinTemperature, max: this.stats.prigoricaMaxTemperature});
        StationSettings.RecordSettings.push({min: gaspinovo.stats.minTemperature, max: gaspinovo.stats.maxTemperature});
        StationSettings.RecordSettings.push({min: this.stats.svAnaMinTemperature, max: this.stats.svAnaMaxTemperature});
    }

    ValidateRecordData(StationSettings) {
        let recordData = StationSettings.RecordSettings;
        recordData.forEach(x => {
            if (x.min == null) {
                x.min = '---';
            }
            if (x.max == null) {
                x.max = '---';
            }
        });
    }

    ValidateNullableData() {
        let stats = this.stats;
        if(stats.ribnicaCurrentTemperature == null && stats.prigoricaCurrentTemperature == null && stats.svAnaCurrentTemperature == null) {

            stats.ribnicaCurrentTemperature = '---';
            stats.ribnicaCurrentHumidity = '---';
            stats.lastUpdatedRibnica = '---';

            stats.prigoricaCurrentTemperature = '---';
            stats.prigoricaCurrentHumidity = '---';
            stats.lastUpdatedPrigorica = '---';

            stats.svAnaCurrentTemperature = '---';
            stats.svAnaCurrentHumidity = '---';
            stats.lastUpdatedSvAna = '---';

            return;
        }

        if(stats.ribnicaCurrentTemperature == null) {
            stats.ribnicaCurrentTemperature = '---';
        }

        if(stats.ribnicaCurrentHumidity == null) {
            stats.ribnicaCurrentHumidity = '---';
        }

        if(stats.lastUpdatedRibnica == null) {
            stats.lastUpdatedRibnica = '---';
        }

        if(stats.prigoricaCurrentTemperature == null) {
            stats.prigoricaCurrentTemperature = '---';
        }

        if(stats.prigoricaCurrentHumidity == null) {
            stats.prigoricaCurrentHumidity = '---';
        }

        if(stats.lastUpdatedPrigorica == null) {
            stats.lastUpdatedPrigorica = '---';
        }

        if(stats.svAnaCurrentTemperature == null) {
            stats.svAnaCurrentTemperature = '---';
        }

        if(stats.svAnaCurrentHumidity == null) {
            stats.svAnaCurrentHumidity = '---';
        }

        if(stats.lastUpdatedsvAna == null) {
            stats.lastUpdatedSvAna = '---';
        }
    }
}

class Original {
    constructor(prigoricaTemperature, prigoricaHumidity, ribnicaTemperature, ribnicaHumidity, svAnaTemperature, svAnaHumidity, date) {
        this.prigoricaTemperature = prigoricaTemperature;
        this.prigoricaHumidity = prigoricaHumidity;
        this.ribnicaTemperature = ribnicaTemperature;
        this.ribnicaHumidity = ribnicaHumidity;
        this.svAnaTemperature = svAnaTemperature;
        this.svAnaHumidity = svAnaHumidity;
        this.date = date;
    }
}

class Stats {
    constructor(
        prigoricaCurrentTemperature,
        prigoricaCurrentHumidity, 
        prigoricaMaxTemperature, 
        prigoricaMinTemperature, 
        lastUpdatedPrigorica, 
        ribnicaCurrentTemperature, 
        ribnicaCurrentHumidity, 
        ribnicaMaxTemperature, 
        ribnicaMinTemperature,
        lastUpdatedRibnica,
        svAnaCurrentTemperature,
        svAnaCurrentHumidity,
        svAnaMaxTemperature,
        svAnaMinTemperature,
        lastUpdatedSvAna) {
        
        this.prigoricaCurrentTemperature = prigoricaCurrentTemperature;
        this.prigoricaCurrentHumidity  = prigoricaCurrentHumidity;
        this.prigoricaMaxTemperature = prigoricaMaxTemperature;
        this.prigoricaMinTemperature = prigoricaMinTemperature;
        this.lastUpdatedPrigorica = lastUpdatedPrigorica;
        this.ribnicaCurrentTemperature = ribnicaCurrentTemperature;
        this.ribnicaCurrentHumidity = ribnicaCurrentHumidity;
        this.ribnicaMaxTemperature = ribnicaMaxTemperature;
        this.ribnicaMinTemperature = ribnicaMinTemperature;
        this.lastUpdatedRibnica = lastUpdatedRibnica;
        this.svAnaCurrentTemperature = svAnaCurrentTemperature;
        this.svAnaCurrentHumidity = svAnaCurrentHumidity;
        this.svAnaMaxTemperature = svAnaMaxTemperature;
        this.svAnaMinTemperature = svAnaMinTemperature;
        this.lastUpdatedSvAna = lastUpdatedSvAna;
    }
}

export {
    Ribnica,
    Original,
    Stats
}